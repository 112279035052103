<template>
  <f7-page class="settings-page">
    <template #fixed>
      <ProfileNavigationComponent :title="$t.getTranslation('LBL_MERCHANT')" type="back" :settings="false" />
    </template>

    <meta name="robots" content="noindex" />

    <div class="bg-content">
      <div class="bg-top"><img :src="require('@/assets/images/bg_top.png')" /></div>
      <div class="settingsinput-container">
        <div class="container">
          <img :src="require('@/assets/images/logo.png')" style="width: 135px; margin-bottom: 10px" />
          <h2>{{ $t.getTranslation("LBL_JOIN_US") }}</h2>
          <p class="subtext" v-html="$t.getTranslation('LBL_SUB_JOIN_US_DESCRIPTION')"></p>

          <f7-list no-hairlines>
            <f7-list-input
              v-model:value="formData.CompanyName"
              name="CompanyName"
              :required="validationRules?.CompanyName?.required"
              :minlength="validationRules?.CompanyName?.minimumLength"
              :maxlength="validationRules?.CompanyName?.maximumLength"
              :label="$t.getTranslation('LBL_COMPANY_NAME')"
              :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
              :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
              floating-label
              outline
              type="text"
              validate
            >
            </f7-list-input>
            <!-- :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')" -->
            <f7-list-input
              v-model:value="formData.Name"
              name="Name"
              :required="validationRules?.Name?.required"
              :minlength="validationRules?.Name?.minimumLength"
              :maxlength="validationRules?.Name?.maximumLength"
              :label="$t.getTranslation('LBL_NAME')"
              :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
              :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
              floating-label
              outline
              type="text"
              validate
            >
            </f7-list-input>

            <f7-list-input
              v-model:value="formData.Email"
              name="Email"
              :required="validationRules?.Email?.required"
              :minlength="validationRules?.Email?.minimumLength"
              :maxlength="validationRules?.Email?.maximumLength"
              :label="$t.getTranslation('LBL_EMAIL')"
              :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
              :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
              floating-label
              outline
              type="text"
              validate
            >
            </f7-list-input>

            <f7-list-input
              v-model:value="formData.Message"
              name="Message"
              :required="validationRules?.Message?.required"
              :minlength="validationRules?.Message?.minimumLength"
              :maxlength="validationRules?.Message?.maximumLength"
              :label="$t.getTranslation('LBL_MESSAGE')"
              :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
              :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
              floating-label
              outline
              type="textarea"
              validate
            >
            </f7-list-input>

            <f7-list-item>
              <f7-button fill large preloader :loading="isButtonProcessing" :disabled="isButtonProcessing || isDisableNextButton" @click="next">
                {{ $t.getTranslation("LBL_SUBMIT") }}
              </f7-button>
            </f7-list-item>
            <div class="item-list" style="margin-top: 10px; width: 100%">
              <ViewItemComponent :title="title" :data="viewData" :fields="contactFields" />
            </div>
          </f7-list>
        </div>
      </div>

      <div class="bg-bot"><img :src="require('@/assets/images/bg_bot.png')" /></div>
    </div>
  </f7-page>
</template>

<script>
import { defineComponent, ref, reactive, inject, computed, onMounted, defineAsyncComponent } from "vue";
import { $HTTP } from "@/utils/axios";
import { post } from "@/utils/axios";
import { helpers } from "@/utils/helpers.js";
import { configs, extendedConfigs } from "@/utils/configs.js";
import { useStore } from "@/store";
import { validations } from "@/utils/validations";
import validationRulesConfig from "@/validationRules";

// import ViewItemComponent from "@/components/ViewItemComponent.vue";
// import ProfileNavigationComponent from "@/components/navigations/ProfileNavigationComponent.vue";

const ViewItemComponent = defineAsyncComponent(() => import(/* webpackChunkName: "view-item" */ /* webpackMode: "lazy" */ "@/components/ViewItemComponent.vue"));
const ProfileNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "profile-nav" */ /* webpackMode: "lazy" */ "@/components/navigations/ProfileNavigationComponent.vue"));

export default defineComponent({
  name: "ContactUsPage",
  components: {
    ViewItemComponent,
    ProfileNavigationComponent,
  },
  props: { f7router: Object },
  setup(props) {
    const $t = inject("$translation");
    const store = useStore();

    const currentLanguageCode = computed(() => store.getters["translation/getLanguage"]);
    const contactFields = ref([{ field: "Contact", name: $t.getTranslation("LBL_OR_EMAIL_US"), format: "string" }]);
    const title = "";

    
    const viewData = ref({
      Contact: extendedConfigs.contact.merchantEmail,
    });

    const formData = reactive({});

    let isButtonProcessing = ref(false);

    let validationRules = {};
    for (let keys in formData) {
      if (validationRulesConfig && validationRulesConfig[keys]) {
        validationRules[keys] = validationRulesConfig[keys];
      }
    }
    onMounted(async () => {});

    const validate = (showNotifications) => {
      let validationRules = {};
      for (let keys in formData) {
        if (validationRulesConfig && validationRulesConfig[keys]) {
          validationRules[keys] = validationRulesConfig[keys];
        }
      }

      const isValid = validations.validate({
        validateData: formData,
        validationRules: validationRules,
        showNotifications: showNotifications,
      });

      return isValid;
    };

    const next = async () => {
      if (isButtonProcessing.value) {
        return;
      }
      isButtonProcessing.value = true;
      let ret = await post("/contact/save/merchant", formData);
      if (ret) {
        isButtonProcessing.value = false;
        helpers.createNotification({
          type: "info",
          title: $t.getTranslation("LBL_INFO"),
          message: $t.getTranslation("LBL_SUCCESS_SAVE_CONTACT"),
        });
        for (var k in formData) {
          formData[k] = "";
        }
        return;
      }
    };

    return {
      formData,
      viewData,
      contactFields,
      next,
      title,
    };
  },
});
</script>
<style scoped>
.item-list {
  margin-top: 20px;
  padding: 0px 30px;
}

.subtext {
  padding: 10px;
  margin: 0px;
  line-height: 18px;
  width: 50%;
}

.list text {
  line-height: 1.6em;
  height: 35px;
}
.settingsinput-container .container {
  min-height: calc(100vh - 200px);
  padding: 90px 0px 0px;
}

.bg-content {
  position: relative;
  min-height: 720px;
}

.bg-bot {
  position: relative;
  bottom: 0px;
  left: 0px;
}

.bg-bot img {
  position: relative;
  margin-top: -110px;
  bottom: -6px;
}
@media only screen and (min-width: 900px){
.subtext {
  width: 90%;
}

}
@media only screen and (max-width: 500px){
.subtext {
    width: 90%;
}
}
</style>
